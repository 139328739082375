import {CURRENCY_CODES, DEFAULT_LOCATION_BY_CURRENCY} from "@/helper"
import {storeToRefs} from "pinia"
import {useAuthStore} from '@/store/auth'
import {getAddressListMutation, getRedBoxLockersMutation} from "~/plugins/API/address";
import {MAP_ERROR_TYPES} from "~/constants";
import {COOKIE_KEYS} from "~/constants/cookie";

export const useAddressStore = defineStore("address", () => {

    const authStore = useAuthStore()
    const {user} = storeToRefs(authStore)


    const currency = useCookie(COOKIE_KEYS.CURRENCY, {secure: true})?.value || CURRENCY_CODES.SAR

    const activeStep = ref(1)

    const currentLocation = ref(DEFAULT_LOCATION_BY_CURRENCY[currency])

    const address = ref(null)

    const errorList = ref([])

    const parcelLockers = ref([])
    const selectedParcelOption = ref('')

    const selectedRedBoxLocker = ref('')

    const addressList = ref([])
    const totalAddress = ref(0)
    const addressLoaded = ref(false)
    const isLoading = ref(true)

    const zoomLevel = ref(6)

    const {mutateAsync} = getAddressListMutation()
    const fetch = ({page = 1, limit = 20} = {}) => {
        mutateAsync({page: page, limit}).then(({data}) => {
            addressList.value = page === 1 ? data.addresses : [...addressList.value, ...data.addresses]
            parcelLockers.value = data.parcel_lockers || []
            totalAddress.value = data.total
            addressLoaded.value = true
        }).finally(() => {
            isLoading.value = false
        })
    }

    const radius = ref(2000)
    const lockerPoints = ref([])

    const {mutateAsync: lockersMutateAsync} = getRedBoxLockersMutation()
    const getLockers = () => {
        lockersMutateAsync({
            lat: currentLocation.value.lat,
            lng: currentLocation.value.lng,
            radius: radius.value
        }).then(({data}) => {
            lockerPoints.value = data.points
            if (!data.points.length) errorList.value.push(MAP_ERROR_TYPES.REDBOX_NOT_FOUND)
        })
    }

    const userInfo = ref({
        custom_field: "",
        default: "0",
        firstname: "",
        lastname: "",
        telephone: ""
    })

    const $reset = () => {
        activeStep.value = 1
        currentLocation.value = DEFAULT_LOCATION_BY_CURRENCY[currency]
        userInfo.value = {
            ...userInfo.value,
            custom_field: "",
            telephone: user.value.telephone,
            firstname: user.value.firstname
        }
        address.value = null
        errorList.value = []
        radius.value = 2000
        lockerPoints.value = []
        selectedParcelOption.value = ""
        selectedRedBoxLocker.value = ""
        zoomLevel.value = 6
    }

    return {
        activeStep,
        currentLocation,
        address,
        userInfo,
        errorList,
        parcelLockers,
        radius,
        selectedParcelOption,
        selectedRedBoxLocker,
        lockerPoints,
        addressList,
        totalAddress,
        addressLoaded,
        zoomLevel,
        loading: isLoading,
        fetch,
        getLockers,
        $reset
    };
});